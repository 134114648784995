import * as React from "react"
import Layout from "../components/general/Layout"
import Intro from "../components/contact/Intro"
import Hoofdgebouw from "../components/contact/Hoofdgebouw"
import Bijgebouw from "../components/contact/Bijgebouw"
import Kanalen from "../components/contact/Kanalen"

const ContactPage = () => {
    return (
      <Layout>
        <div className="">
          <Intro></Intro>
          <Kanalen></Kanalen>
          <Hoofdgebouw></Hoofdgebouw>
          <Bijgebouw></Bijgebouw>
        </div>
      </Layout>
    );
}

export default ContactPage
