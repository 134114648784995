import React from "react";

const Kanalen = () => {
  return (
    <div className="bg-gray-white py-36 font-inter">
      <div className="grid grid-cols-12">
        {" "}
        <div className="col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-2 2xl:col-span-2 "></div>
        <div className="col-span-10 md:col-span-8 lg:col-span-4 xl:col-span-3 2xl:col-span-3  bg-blue-effect p-4 py-16 xl:py-12 2xl:py-16">
          <div className="flex flex-col justify-center items-center h-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
              className=" fill-current text-gray-white"
              width="40"
              height="40"
            >
              <title>Mail</title>
              <rect
                x="48"
                y="96"
                width="416"
                height="320"
                rx="40"
                ry="40"
                fill="none"
                stroke="#F0F0F0"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
              />
              <path
                fill="none"
                stroke="#F0F0F0"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
                d="M112 160l144 112 144-112"
              />
            </svg>
            <div className="text-lg sm:text-2xl text-gray-white  text-center mt-6">
              <span>info@cvt-advocaten.be</span>
            </div>
          </div>
        </div>
        <div className="col-span-1 md:col-span-2  lg:hidden"></div>
        <div className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2  "></div>
        <div className="col-span-10 md:col-span-8 lg:col-span-4 xl:col-span-3 2xl:col-span-3 bg-black-effect p-16 xl:p-12 2xl:p-16 mt-16 lg:mt-0">
          <div className="flex flex-row justify-center items-center w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
              className=" text-center fill-current text-gray-white"
              width="40"
              height="40"
            >
              <title>Call</title>
              <path
                d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z"
                fill="none"
                stroke="#F0F0F0"
                stroke-miterlimit="10"
                stroke-width="32"
              />
            </svg>
          </div>

          <div className="text-lg sm:text-2xl text-gray-white  text-center mt-8 ">
            Aalst <br /> 053 22 90 86 <br /> <br /> Opwijk <br /> 052 52 73 91{" "}
            <br /> <span></span>
          </div>
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-1  xl:col-span-2 2xl:col-span-2"></div>
      </div>
    </div>
  );
};

export default Kanalen;
